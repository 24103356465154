import clevertap from 'clevertap-web-sdk';

import { environment } from '../../client/config/environment';
import { globalErrorHandler } from '../../utils/LogUtils';

type Variable = string | number | boolean;

type GroupedVariables = Record<string, Record<string, Variable>>;

const CT_VARS_LOCAL_STORAGE_KEY = 'WZRK_PE';

class CleverTapService {
  public groupedVariables: GroupedVariables = {};
  public variables: Record<string, Variable> = {};

  init() {
    clevertap.init(environment.CT_ACCOUNT_ID, environment.CT_REGION, undefined, environment.CT_TOKEN);
    // clevertap.setLogLevel(4); // 4 is development mode

    this.parseVarsGroupsToObjects();

    return clevertap;
  }

  onFetchVariables (callback: () => void) {
    clevertap.fetchVariables(() => {
      this.parseVarsGroupsToObjects();
      callback();
    })
  }

  parseVarsGroupsToObjects() {
    try {
      const varsString = decodeURIComponent(localStorage.getItem(CT_VARS_LOCAL_STORAGE_KEY));

      this.variables = JSON.parse(varsString);

      for (const varKey in this.variables) {
        if (varKey.includes('.')) {
          const [group, key] = varKey.split('.');

          this.groupedVariables[group] = this.groupedVariables[group] ? { ...this.groupedVariables[group], [key]: this.variables[varKey] } : {};
        }
      }

    } catch (error) {
      globalErrorHandler({ error, filename: 'ClevertapService.parseVarsGroupsToObjects', info: 'CT variables parsing issue' });
    }
  }

  // TODO: please remove this method once migrate to CT
  migrateLPVarsToCT(LPVariables: Array<Record<string, Variable>>, CTgroupName: string) {
    LPVariables.forEach((LPVariable, index) => {
      for (const key in LPVariable) {
        // to set variables grouping use dot notation "groupName.varName"
        const variableName = `${CTgroupName}${index}.${key}`;

        clevertap.defineVariable(variableName, LPVariable[key]);
      }
    });

    // sets default values vor defined variables
    clevertap.syncVariables(
      () => console.log('Sync CT variables is successful'),
      () => console.log('Sync CT variables failed')
    );
  }
}

export default new CleverTapService();
